import * as React from "react";
import { Box, Flex } from "rebass";
import PropTypes from "prop-types";
import { Waypoint } from "react-waypoint";
import { isBrowser, BrowserView } from "react-device-detect";
import { height } from "styled-system";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import { H2, P } from "../Typography";
import Image from "../Image";
import Frame from "../Frame";
import MoreButton from "../Button";
import Player from "../Player";
import Link from "../Link";
import { useScroll } from "../../hooks/scroll";

const hoverStyle = `
  :hover {
    #description, 
    #button {
      opacity: 1; 
      transform: translateY(0px);
    } 
    #image {
      opacity: 0;
    } 
    #video {
      opacity: 1;
    }
  }`;

const CaseStudy = ({ bgFluid }) => {
  const [isVisible, handleChangePosition] = useScroll();
  return (
    <Frame
      name="caseStudy"
      height={["300px", "460px", "700px", null]}
      py={[2, 6, 8, null]}
      css={`
        opacity: ${isVisible ? 1 : 0};
        transform: ${isVisible ? "translateY(0px)" : "translateY(70px)"};
        transition: all 0.4s ease-out;
        position: relative;
        overflow: hidden;
        ${isBrowser ? hoverStyle : null}
        ${height}
      `}
    >
      <Waypoint bottomOffset="20%" onPositionChange={handleChangePosition} />
      <Box
        css={`
          background: linear-gradient(
            90deg,
            #0d121a 0%,
            rgba(30, 36, 47, 0) 60.55%
          );
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0px;
          top: 0px;
        `}
      />
      <Box
        id="image"
        css={{
          position: "absolute",
          left: "0px",
          top: "0px",
          minHeight: isBrowser ? "591px" : null,
          height: "100%",
          width: "100%",
          transitionDuration: "0.4s",
          transitionProperty: "opacity",
          zIndex: -1
        }}
      >
        <Image
          fluid={bgFluid}
          fadeIn
          durationFadeIn={1000}
          css={{
            width: "100%",
            zIndex: -1,
            minHeight: isBrowser ? "591px" : null,
            height: "100%",
            overflow: "hidden"
          }}
        />
      </Box>
      <BrowserView>
        <Player
          id="video"
          src="https://www.youtube.com/watch?v=5jVjp8TRxqY"
          width="100%"
          playing
          volume={0}
          loop
          muted
          style={{ zIndex: -2 }}
        />
      </BrowserView>
      <Flex height="100%" flexDirection="column">
        <H2 mb={3} color="white" css={{ zIndex: 1 }}>
          Watch our
          <br />
          Сase Study
        </H2>
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          flexGrow={1}
        >
          <P
            id="description"
            color="white"
            css={
              isBrowser
                ? {
                    opacity: 0,
                    transform: "translateY(50px)",
                    transitionDuration: "0.4s",
                    transitionProperty: "transform opacity"
                  }
                : null
            }
            style={{ zIndex: 1 }}
          >
            Full UA campaing for beatmaking app.
          </P>
          <Box
            id="button"
            css={
              isBrowser
                ? {
                    opacity: 0,
                    transform: "translateY(50px)",
                    transitionDuration: "0.4s",
                    transitionProperty: "transform opacity"
                  }
                : null
            }
          >
            <Link
              to="/case-studies"
              onClick={() => {
                trackCustomEvent({
                  category: "Click",
                  action: "CaseStudy",
                  label: "FromSection"
                });
              }}
            >
              <MoreButton
                buttonColor="white"
                style={{ backgroundColor: "transparent" }}
              />
            </Link>
          </Box>
        </Flex>
      </Flex>
    </Frame>
  );
};

CaseStudy.propTypes = {
  bgFluid: PropTypes.shape().isRequired
};

export default CaseStudy;
