import * as React from "react";
import { Box, Flex } from "rebass";
import { Waypoint } from "react-waypoint";
import AtlIcon from "../../assets/icons/atl.svg";
import GmgIcon from "../../assets/icons/gmg.svg";
import LogtipsIcon from "../../assets/icons/logtips.svg";
import MarmeladIcon from "../../assets/icons/marmelad.svg";
import MegaIcon from "../../assets/icons/mega.svg";
import UbeatsIcon from "../../assets/icons/ubeats.svg";
import { H3 } from "../Typography";
import colors from "../../utils/theme/colors";
import { useScroll } from "../../hooks/scroll";

const style = {
  position: "absolute",
  top: "15%",
  left: "15%",
  width: "70%",
  height: "70%"
};

const OurClientsLogos = [
  <LogtipsIcon style={style} />,
  <MegaIcon style={style} />,
  <AtlIcon style={style} />,
  <GmgIcon style={style} />,
  <UbeatsIcon style={style} />,
  <MarmeladIcon style={style} />
];

const OurClients = () => {
  const [isVisible, handleChangePosition] = useScroll();

  return (
    <>
      <Waypoint bottomOffset="20%" onPositionChange={handleChangePosition} />
      <H3
        mb={[5, 6, 7, null]}
        css={{
          opacity: isVisible ? 1 : 0,
          transform: isVisible ? "translateY(0px)" : "translateY(70px)",
          transition: "all 0.4s ease-out",
          transitionDelay: isVisible ? "0s" : `0.${OurClientsLogos.length}s`
        }}
      >
        Our client
      </H3>
      <Flex
        justifyContent="space-between"
        css={`
          @media (max-width: 1279px) {
            flex-wrap: wrap;
          }
        `}
      >
        {OurClientsLogos.map((logo, index) => (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            width={[
              `calc((100% - 20px * (${
                OurClientsLogos.length
              } - 5)) / ${OurClientsLogos.length / 3})`,
              `calc((100% - 40px * (${
                OurClientsLogos.length
              } - 4)) / ${OurClientsLogos.length / 2})`,
              `calc((100% - 50px * (${OurClientsLogos.length} - 1)) / ${OurClientsLogos.length})`
            ]}
            pt={[
              `calc((100% - 20px * (${
                OurClientsLogos.length
              } - 5)) / ${OurClientsLogos.length / 3})`,
              `calc((100% - 40px * (${
                OurClientsLogos.length
              } - 4)) / ${OurClientsLogos.length / 2})`,
              `calc((100% - 50px * (${OurClientsLogos.length} - 1)) / ${OurClientsLogos.length})`
            ]}
            backgroundColor={colors.white}
            css={`
              position: relative;
              ::after {
                content: "";
                display: block;
                padding-top: calc(
                  (100% - 50px * (${OurClientsLogos.length} - 1)) /
                    ${OurClientsLogos.length}
                );
              }
              @media (max-width: 1279px) {
                margin-bottom: ${index < 3 ? 40 : 0}px !important;
              }
              @media (max-width: 767px) {
                margin-bottom: ${index < 4 ? 20 : 0}px !important;
              }
              opacity: ${isVisible ? 1 : 0};
              transform: ${isVisible ? "translateY(0px)" : "translateY(70px)"};
              transition: all 0.4s ease-out;
              transition-delay: ${isVisible
                ? `0.${index + 1}s`
                : `0.${OurClientsLogos.length - 1 - index}s`};
            `}
          >
            {logo}
          </Box>
        ))}
      </Flex>
    </>
  );
};

export default OurClients;
