import React from "react";
import { Flex } from "rebass";
import PropTypes from "prop-types";
import { Waypoint } from "react-waypoint";
import { H3 } from "../Typography";
import AnimationBox from "./AnimationBox";
import { useScroll } from "../../hooks/scroll";

const OurServices = ({ data: { title, services } }) => {
  const [isVisible, handleChangePosition] = useScroll();

  return (
    <>
      <Waypoint bottomOffset="20%" onPositionChange={handleChangePosition} />
      <H3
        mb={[5, 6, 7, null]}
        css={{
          opacity: isVisible ? 1 : 0,
          transform: isVisible ? "translateY(0px)" : "translateY(70px)",
          transition: "all 0.4s ease-out",
          transitionDelay: isVisible ? "0s" : "0.3s"
        }}
      >
        {title}
      </H3>
      <Flex
        justifyContent="space-between"
        css={`
          @media (max-width: 1280px) {
            flex-wrap: wrap;
          }
        `}
      >
        {services.map(({ title: serviceTitle, text, animation }, index) => (
          <AnimationBox
            key={serviceTitle}
            type={animation}
            rotate={animation === "triangle" ? 360 : 180}
            title={serviceTitle}
            text={text}
            style={{
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? "translateY(0px)" : "translateY(70px)",
              transition: "all 0.4s ease-out",
              transitionDelay: isVisible ? `0.${index + 1}s` : `0.${2 - index}s`
            }}
          />
        ))}
      </Flex>
    </>
  );
};

OurServices.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    services: PropTypes.arrayOf.isRequired
  }).isRequired
};

export default OurServices;
