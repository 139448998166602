export const getRndInteger = max => {
  return Math.floor(Math.random() * (max - 0 + 1)) + 0;
};

export const getArray = array => {
  const currentArray = [...array];
  const result = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 8; i++) {
    result.push(
      ...currentArray.splice(getRndInteger(currentArray.length - 1), 1)
    );
  }
  return result;
};
