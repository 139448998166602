import * as React from "react";
import { Box, Flex } from "rebass";
import { Waypoint } from "react-waypoint";
import { isBrowser } from "react-device-detect";
import { navigate } from "gatsby";

import Button from "../Button/MoreButton";
import Image from "../Image";
import { H3, H4, P } from "../Typography";

import { useScroll } from "../../hooks/scroll";
import colors from "../../utils/theme/colors";

import MegaIcon from "../../assets/icons/mega.svg";

const style = {
  position: "absolute",
  top: "15%",
  left: "15%",
  width: "70%",
  height: "30%"
};

const BlogImages = [
  <MegaIcon style={style} />,
  <MegaIcon style={style} />,
  <MegaIcon style={style} />
];

// eslint-disable-next-line react/prop-types
const RecentFromBlog = ({ data }) => {
  const [isVisible, handleChangePosition] = useScroll();
  return (
    <>
      <Waypoint bottomOffset="20%" onPositionChange={handleChangePosition} />
      <H3
        mb={[1, 2, 2, null]}
        css={{
          opacity: isVisible ? 1 : 0,
          transform: isVisible ? "translateY(0px)" : "translateY(70px)",
          transition: "all 0.4s ease-out",
          transitionDelay: isVisible ? "0s" : `0.${BlogImages.length}s`
        }}
      >
        Recent From Blog
      </H3>
      <Box
        id="button"
        css={
          isBrowser
            ? {
                opacity: isVisible ? 1 : 0,
                transform: isVisible ? "translateY(0px)" : "translateY(50px)",
                transitionDuration: "all 0.4s ease-out",
                transitionProperty: isVisible ? "0s" : `0.${BlogImages.length}s`
              }
            : null
        }
        mb={[5, 6, 7, 8]}
      >
        <Button
          onClick={() => navigate(`blog`)}
          buttonColor="black"
          style={{ backgroundColor: "transparent" }}
        />
      </Box>
      <Flex mb={[2, 6, 7]} flexDirection={["column", "row", "row", "row"]}>
        {data.map(
          ({
            node: {
              fields: { slug },
              frontmatter: { title: postTitle, postImage, introtext }
            }
          }) => (
            <Box
              key={slug}
              onClick={() => navigate(slug)}
              mb={[5, 0, 0]}
              width={["100%", `${100 / 3}%`, `${100 / 3}%`, `${100 / 3}%`]}
              color={colors.black}
              css={`
                cursor: pointer;
                .postImage {
                  position: relative;
                  top: 15px;
                  transition: top 0.3s;
                }
                :hover {
                  .postImage {
                    top: 0px;
                    transition: top 0.3s;
                  }
                }
              `}
            >
              <Image
                className="postImage"
                fluid={postImage.childImageSharp.fluid}
                loading="lazy"
              />
              <H4 mt={[2, 2, 3]} mb={[1, 1, "15px"]}>
                {postTitle}
              </H4>
              <P
                css="
                      display: -webkit-box;
                      height: auto;
                      overflow: hidden;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                    "
              >
                {introtext}
              </P>
            </Box>
          )
        )}
      </Flex>
    </>
  );
};

export default RecentFromBlog;
