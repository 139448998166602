/* eslint-disable no-irregular-whitespace */
import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Box, Flex, Button } from "rebass";
import { display, bottom, width, height } from "styled-system";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import PropTypes from "prop-types";
import OurClients from "../components/OurClients";
import Frame from "../components/Frame";
import OurServices from "../components/OurServices";
import CaseStudy from "../components/CaseStudy";
import { H1, H3, H5 } from "../components/Typography";
import SocialIcons from "../components/SocialIcons";
import Navigation from "../components/Navigation";
import ScrollDownIcon from "../assets/icons/scrollDown.svg";
import OurWorkdays from "../components/OurWorkdays";
import colors from "../utils/theme/colors";
import PlayIcon from "../assets/icons/play.svg";
import Image from "../components/Image";
import Video from "../components/Video";
import Showreel from "../assets/video/showreel.mp4";
import Modal from "../components/Modal";
import Player from "../components/Player";
import { useModal } from "../hooks/modal";
import SEO from "../components/SEO";
import Link from "../components/Link";
import RecentFromBlog from "../components/RecentFromBlog";

const IndexPage = ({ loaded }) => {
  const {
    site: {
      siteMetadata: { titleTemplate, siteUrl }
    },
    homePageInfo: {
      frontmatter: { seoTitle, description, keywords, longTitle }
    },
    servicesContent: { frontmatter: servicesContent },
    caseStudyBgImage: {
      childImageSharp: { fluid: caseStudyBgFluid }
    },
    ourWorkdaysContent: { frontmatter: ourWorkdaysContent },
    bgMobile: {
      childImageSharp: { fluid: bgMobileFluid }
    },
    SEOImage: {
      childImageSharp: { fluid: SEOImageFluid }
    },
    showreelImage: {
      childImageSharp: { fluid: showreelImage }
    },
    blogPosts: { edges: blogPosts }
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          titleTemplate
          siteUrl
        }
      }
      homePageInfo: markdownRemark(frontmatter: { name: { eq: "home" } }) {
        frontmatter {
          seoTitle
          description
          keywords
          longTitle
        }
      }
      servicesContent: markdownRemark(
        frontmatter: { collection_name: { eq: "our-services" } }
      ) {
        frontmatter {
          title
          services {
            title
            text
            animation
          }
        }
      }
      caseStudyBgImage: file(relativePath: { eq: "images/bg-case-study.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      ourWorkdaysContent: markdownRemark(
        frontmatter: { collection_name: { eq: "our-workdays" } }
      ) {
        frontmatter {
          title
          images {
            id
            childImageSharp {
              original {
                width
              }
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      showreelImage: file(relativePath: { eq: "images/showreel.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 440) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      showreelImage: file(relativePath: { eq: "images/showreel.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 440) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      bgMobile: file(relativePath: { eq: "images/bg-mobile.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      SEOImage: file(relativePath: { eq: "images/main-page_1440-1.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      blogPosts: allMarkdownRemark(
        filter: { frontmatter: { collection_name: { eq: "blog-posts" } } }
        sort: { fields: frontmatter___date, order: DESC }
        limit: 3
      ) {
        edges {
          node {
            fileAbsolutePath
            fields {
              slug
            }
            frontmatter {
              category
              title
              introtext
              postImage {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const [isOpenModal, openModal, closeModal] = useModal();

  const [isStartAnimation, setIsStartAnimation] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    if (loaded) {
      setIsStartAnimation(true);
    }
  }, [setIsStartAnimation, loaded]);

  return (
    <>
      <SEO
        titleTemplate={titleTemplate}
        title={seoTitle}
        keywords={keywords}
        url={siteUrl}
        description={description}
        image={`${siteUrl}${SEOImageFluid.src}`}
      />
      <Frame
        pt={[3, 5, null, null]}
        pb={[2, 6, 8, 11]}
        color="white"
        height="100vh"
        css={`
          overflow: hidden;
          position: relative;
          @media (max-width: 828px) and (orientation: landscape) {
            min-height: 820px;
            overflow: hidden;
          }
          @media (max-width: 767px) and (orientation: landscape) {
            min-height: 670px;
            overflow: hidden;
          }
        `}
      >
        <Image
          fluid={bgMobileFluid}
          css={`
            width: 100%;
            z-index: -1;
            left: 0px;
            top: 0px;
            right: 0px;
            bottom: 0px;
            margin: auto;
            position: absolute !important;
            display: none !important;
            @media (max-width: 767px) {
              display: block !important;
            }
          `}
        />
        <Video
          src={Showreel}
          autoPlay
          muted
          loop
          display={["none", "block", "block", null]}
          css={`
            width: 100%;
            z-index: -2;
            left: 0px;
            top: 0px;
            right: 0px;
            bottom: 0px;
            margin: auto;
            position: absolute;
            ${display}
            @media (max-width: 1920px) {
              min-width: 140% !important;
            }
            @media (max-width: 1280px) {
              min-width: 280% !important;
            }
          `}
        />
        <Navigation />
        <Flex
          justifyContent="space-between"
          height="calc(100% - 30px)"
          flexDirection={["column", "row", null, null]}
        >
          <Flex
            flexDirection="column"
            justifyContent="center"
            pt={[7, 0, null]}
          >
            <H1
              mb={2}
              css={{
                opacity: isStartAnimation ? 1 : 0,
                transform: isStartAnimation
                  ? "translateY(0px)"
                  : "translateY(100px)",
                transition: "all 0.4s ease-out",
                transitionDelay: "0.2s"
              }}
            >
              Advertisement
              <br />
              and promotional
              <br />
              videos for Products
              <br />
              and Companies
            </H1>
            <H5
              width={[1, 0.7, 1, null]}
              mb={40}
              css={{
                opacity: isStartAnimation ? 1 : 0,
                transform: isStartAnimation
                  ? "translateY(0px)"
                  : "translateY(100px)",
                transition: "all 0.4s ease-out",
                transitionDelay: "0.2s"
              }}
            >
              {longTitle}
            </H5>
            <Link
              href="#contactUs"
              width="max-content"
              onClick={() => {
                trackCustomEvent({
                  category: "Click",
                  action: "HitUsUp",
                  label: "MainPage"
                });
              }}
              css={{
                opacity: isStartAnimation ? 1 : 0,
                transform: isStartAnimation
                  ? "translateY(0px)"
                  : "translateY(100px)",
                transition: "all 0.4s ease-out",
                transitionDelay: "0.3s"
              }}
            >
              <Button
                variant="headerButton"
                css={`
                  @media (min-width: 1280px) {
                    :hover {
                      background-color: transparent;
                      color: white;
                    }
                  }
                `}
              >
                hit us up
              </Button>
            </Link>
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="flex-end"
            css={`
              min-width: auto !important;
            `}
          >
            <SocialIcons
              css={`
                display: flex !important;
                @media (max-width: 767px) {
                  display: none !important;
                }
              `}
              iconProps={{
                mb: [0, "25px", null, null],
                css: {
                  ":last-child": {
                    marginBottom: "0px"
                  }
                }
              }}
            />
            <H3
              mt={[0, 230, 8, null]}
              mb={2}
              width={1}
              textAlign={["end", "start", null, null]}
              css={{
                opacity: isStartAnimation ? 1 : 0,
                transform: isStartAnimation
                  ? "translateY(0px)"
                  : "translateY(100px)",
                transition: "all 0.4s ease-out",
                transitionDelay: "0.2s"
              }}
            >
              Showreel
            </H3>
            <Box
              width={[120, 160, 220, null]}
              height={[120, 160, 220, null]}
              onClick={e => {
                e.preventDefault();
                openModal();
                trackCustomEvent({
                  category: "Click",
                  action: "Showreel"
                });
              }}
              css={`
                border: 4px solid ${colors.white};
                background-color: ${colors.secondary1};
                position: relative;
                overflow: hidden;
                cursor: pointer;
                @media (min-width: 1280px) {
                  :hover {
                    #image {
                      opacity: 0.2;
                      transform: scale(1.2);
                    }
                    #playIcon {
                      transform: scale(1.2);
                    }
                  }
                }
                opacity: ${isStartAnimation ? 1 : 0};
                transform: ${isStartAnimation
                  ? "translateY(0px)"
                  : "translateY(100px)"};
                transition: all 0.4s ease-out;
                transition-delay: 0.3s;
              `}
            >
              <Box id="image" css={{ transition: "all 0.2s ease-out" }}>
                <Image
                  fluid={showreelImage}
                  alt="showreel"
                  width={[112, 158, 212, null]}
                  height={[112, 158, 212, null]}
                />
              </Box>
              <Box
                id="playIcon"
                css={`
                  width: 74px;
                  height: 74px;
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                  bottom: 0px;
                  margin: auto !important;
                  transition: all 0.2s ease-out;
                  @media (max-width: 1279px) {
                    width: 64px;
                    height: 64px;
                  }
                  @media (max-width: 767px) {
                    width: 52px;
                    height: 52px;
                  }
                `}
              >
                <PlayIcon
                  style={{
                    width: "100%",
                    height: "100%"
                  }}
                />
              </Box>
            </Box>
          </Flex>
        </Flex>
        <Box
          bottom={["20px", "60px", "80px", null]}
          width={["16px", "20px", null, null]}
          height={["55.5px", "70px", null, null]}
          css={`
            position: absolute;
            ${bottom}
            ${width}
            ${height}
          `}
        >
          <ScrollDownIcon height="100%" width="100%" />
        </Box>
      </Frame>
      <Frame p={[10, 15, 20, 25]} id="ourServices">
        <OurServices data={servicesContent} />
      </Frame>
      <CaseStudy bgFluid={caseStudyBgFluid} />
      <Frame p={[10, 15, 20, 25]}>
        <OurClients />
      </Frame>
      <Frame px={1} pb={1}>
        <OurWorkdays data={ourWorkdaysContent} />
      </Frame>
      <Frame p={[10, 15, 20, 25]}>
        <RecentFromBlog data={blogPosts} />
      </Frame>
      <Modal closeModal={closeModal} isOpenModal={isOpenModal}>
        <Player
          src="https://www.youtube.com/watch?v=1PMWWCp5SG4"
          controls
          width="100%"
          height="0px"
        />
      </Modal>
    </>
  );
};

export default IndexPage;

IndexPage.propTypes = {
  loaded: PropTypes.bool.isRequired
};
