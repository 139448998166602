import * as React from "react";
import { Box } from "rebass";
import styled from "styled-components";
import * as PropTypes from "prop-types";
import { H4, P } from "../Typography";

const Square = styled.div`
  width: 40px;
  height: 40px;
  background-color: #0a87be;
`;

const Circle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: #0a87be;
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 23.094px solid transparent;
  border-right: 23.094px solid transparent;
  border-bottom: 40px solid #0a87be;
`;

const AnimationBox = ({ type, rotate, title, text, style }) => {
  const AnimationContent = ({ contentType }) => {
    switch (contentType) {
      case "square":
        return <Square />;
      case "circle":
        return <Circle />;
      case "triangle":
        return <Triangle />;
      default:
        return <Circle />;
    }
  };

  AnimationContent.propTypes = {
    contentType: PropTypes.string.isRequired
  };

  return (
    <Box
      width="calc(100% / 3 - 110px)"
      css={`
        position: relative;
        @media (min-width: 1280px) {
          :hover {
            #animationBox {
              transform: rotate(${rotate}deg);
              top: 0px;
              left: 0px;
            }
            #contentBox {
              transform: translateY(-50px);
            }
          }
        }
        @media (max-width: 1279px) {
          min-width: 400px !important;
          margin-bottom: 70px !important;
          :last-child {
            margin-bottom: 0px !important;
          }
        }
        @media (max-width: 960px) {
          min-width: 310px !important;
        }
        @media (max-width: 767px) {
          min-width: 100% !important;
          margin-bottom: 70px !important;
          :last-child {
            margin-bottom: 0px !important;
          }
        }
        ${style}
      `}
    >
      <Box width={100} height={100} mb={4}>
        <Box
          id="animationBox"
          css={{
            position: "absolute",
            top: "0px",
            left: "0px",
            transitionDuration: "0.4s",
            transitionProperty: "transform top"
          }}
        >
          <AnimationContent contentType={type} />
        </Box>
        <Box
          id="animationBox"
          css={{
            position: "absolute",
            top: "0px",
            left: "60px",
            transitionDuration: "0.4s",
            transitionProperty: "transform top"
          }}
        >
          <AnimationContent contentType={type} />
        </Box>
        <Box
          id="animationBox"
          css={{
            position: "absolute",
            top: "60px",
            left: "0px",
            transitionDuration: "0.4s",
            transitionProperty: "transform top"
          }}
        >
          <AnimationContent contentType={type} />
        </Box>
        <Box
          id="animationBox"
          css={{
            position: "absolute",
            top: "60px",
            left: "60px",
            transitionDuration: "0.4s",
            transitionProperty: "transform top"
          }}
        >
          <AnimationContent contentType={type} />
        </Box>
      </Box>
      <Box
        id="contentBox"
        css={{
          transitionDuration: "0.4s",
          transitionProperty: "transform"
        }}
      >
        <H4 mb={2}>{title}</H4>
        <P>{text}</P>
      </Box>
    </Box>
  );
};

AnimationBox.propTypes = {
  type: PropTypes.string,
  rotate: PropTypes.number,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  style: PropTypes.shape()
};

AnimationBox.defaultProps = {
  type: "Circle",
  rotate: 180,
  style: {}
};

export default AnimationBox;
