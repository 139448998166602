import * as React from "react";
import { Button as RebassButton } from "rebass";
import { isBrowser } from "react-device-detect";
import ArrowIcon from "../../assets/icons/arrow.svg";
import BlackArrowIcon from "../../assets/icons/arrowBlack.svg";
import { P } from "../Typography";
import color from "../../utils/theme/colors";

// eslint-disable-next-line react/prop-types
export const MoreButton = ({ buttonColor, ...props }) => (
  <RebassButton
    {...props}
    css={`
      width: 60px;
      height: 50px;
      padding: 11px 20px !important;
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      border: 2px solid ${color[buttonColor]} !important;
      overflow: hidden;
      background-color: transparent;
      transition-duration: 0.3s;
      transition-property: width;
      border-radius: 0px !important;
      ${isBrowser
        ? ":hover {width: 125px; #buttonText {transform: translateX(0px);}}"
        : "width: 125px;"}
    `}
  >
    <P
      id="buttonText"
      color={buttonColor}
      css={
        isBrowser
          ? {
              transform: "translateX(-70px)",
              transitionDuration: "0.3s",
              transitionProperty: "transform"
            }
          : null
      }
    >
      MORE
    </P>
    {buttonColor === "black" ? (
      <BlackArrowIcon style={{ minWidth: "18px", minHeight: "11px" }} />
    ) : (
      <ArrowIcon style={{ minWidth: "18px", minHeight: "11px" }} />
    )}
  </RebassButton>
);

export default MoreButton;
