import React from "react";
import * as PropTypes from "prop-types";
import { Box } from "rebass";

const Video = ({ src, ...props }) => (
  <Box as="video" {...props}>
    <source src={src} type="video/mp4" />
  </Box>
);

Video.propTypes = {
  src: PropTypes.string.isRequired
};

export default Video;
