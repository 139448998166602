import React, { useEffect, useState } from "react";
import { Box } from "rebass";
import PropTypes from "prop-types";
import { Waypoint } from "react-waypoint";
import { H3 } from "../Typography";
import Image from "../Image";
import { getArray } from "../../utils/data";
import { useScroll } from "../../hooks/scroll";

const OurWorkdays = ({ data: { title, images } }) => {
  const [isVisible, handleChangePosition] = useScroll();
  const [content, setContent] = useState(getArray(images));
  useEffect(() => {
    setContent(getArray(images));
  }, [setContent, images]);
  return (
    <>
      <Waypoint bottomOffset="20%" onPositionChange={handleChangePosition} />
      <Box
        css={`
          display: grid;
          grid-template-columns: repeat(4, minmax(24%, 1fr));
          grid-gap: 10px;
          @media (max-width: 767px) {
            grid-template-columns: repeat(2, minmax(48%, 1fr));
          }
        `}
      >
        <Box
          p={4}
          backgroundColor="secondary1"
          css={`
            opacity: ${isVisible ? 1 : 0};
            transform: ${isVisible ? "translateY(0px)" : "translateY(70px)"};
            transition: all 0.4s ease-out;
            transition-delay: ${isVisible ? "0s" : "0.8s"};
            @media (max-width: 1279px) {
              grid-column-start: 1;
              grid-row-start: 1;
              grid-row-end: 3;
              grid-column-end: 3;
            }
            @media (max-width: 767px) {
              height: calc(100vw - 10px);
            }
          `}
        >
          <Box p={3} height="100%" css={{ border: "4px solid white" }}>
            <H3 color="white">{title}</H3>
          </Box>
        </Box>
        {content.length &&
          content.map(({ id, childImageSharp }, index) => (
            <Box
              key={id}
              css={`
                padding-top: 100%;
                box-sizing: border-box;
                position: relative;
                :last-child {
                  display: none;
                }
                @media (max-width: 1279px) {
                  :last-child {
                    display: block;
                  }
                }
                @media (max-width: 767px) {
                  :nth-child(2),
                  :last-child {
                    display: none;
                  }
                }
                opacity: ${isVisible ? 1 : 0};
                transform: ${isVisible
                  ? "translateY(0px)"
                  : "translateY(70px)"};
                transition: all 0.4s ease-out;
                transition-delay: ${isVisible
                  ? `0.${index + 1}s`
                  : `0.${8 - 1 - index}s`};
              `}
            >
              <Box
                css={{
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                  left: "0px",
                  bottom: "0px"
                }}
              >
                <Image fluid={childImageSharp.fluid} css={{ height: "100%" }} />
              </Box>
            </Box>
          ))}
      </Box>
    </>
  );
};

OurWorkdays.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    images: PropTypes.arrayOf.isRequired
  }).isRequired
};

export default OurWorkdays;
